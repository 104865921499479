<template>
  <div>
    <div class="card card-info">
      <div class="card-header pt-2 pb-2">
        <a
          data-toggle="collapse"
          href="#empresas"
          aria-expanded="true"
          id="heading-empresas"
          class="d-block"
        >
          <i class="fa fa-chevron-down pull-right"></i>
          Empresas
        </a>
      </div>
      <div id="empresas" class="collapse">
        <div class="card-body">
          <div class="row">
            <div class="col-md-10">
              <v-select
                :class="[
                  $v.empresa.id.$invalid ? 'is-invalid' : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                v-model="empresa"
                placeholder="Empresas"
                label="razon_social"
                class="form-control form-control-sm p-0"
                :options="empresas"
                :filterable="false"
                @search="buscarEmpresas"
                @input="selectEmpresa()"
              ></v-select>
            </div>
            <div class="col-md-2">
              <button
                class="btn btn-sm btn-info"
                v-show="!$v.empresa.$invalid"
                @click="save(true)"
              >
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <table
                class="table table-striped table-hover table-head-fixed text-nowrap table-sm"
              >
                <thead>
                  <tr>
                    <th>Empresa</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list_empresas" :key="item.id">
                    <td>{{ item.razon_social }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="save(false, item.id)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UsuarioEmpresa",
  components: {
    vSelect,
  },
  data() {
    return {
      form_empresa: {},
      empresas: [],
      list_empresas: [],
      empresa: {},
    };
  },
  validations: {
    empresa: {
      id: {
        required,
      },
    },
  },
  methods: {
    index() {
      axios
        .get("/api/admin/usuarios/empresas?usuario=" + this.$parent.form.id)
        .then((response) => {
          this.list_empresas = response.data;
        });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            //q: search;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectEmpresa() {
      this.form_empresa.empresa_id = this.empresa.id;
      this.form_empresa.user_id = this.$parent.form.id;
    },

    save(accion, emp) {
      if (this.list_empresas.length > 0 && accion) {
        this.$swal({
          icon: "error",
          title: "No puede asignar más de una empresa por usuario.",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        return false;
      }
      let empresa = this.form_empresa.empresa_id;
      if (!accion) {
        empresa = emp;
      }
      axios
        .put("/api/admin/usuarios/assingOrRevokeEmpresa", {
          user: this.$parent.form.id,
          empresa: empresa,
          accion: accion,
        })
        .then((response) => {
          this.index();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },
  },
  mounted() {
    this.index();
  },
};
</script>
